<template>
    <div>
        <VJoyDialog id="termsAndConditionsDialog" ref="termsAndConditionsDialog" pattern-confirm :focus-trap="false">
            <template #dialog-header>
                <p>{{ $t('legal.acceptance.popup.title') }}</p>
            </template>

            <template #dialog-body>
                <div class="tc__dialog-body">
                    <MaltImg height="160" width="175" class="banner-img" src="/legal/legal-acceptance-illus" alt="" />
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <div v-html="$t('legal.acceptance.popup.message', ['/legal'])" />
                </div>
            </template>

            <template #dialog-confirm>
                <VJoyButton variant="main" class="joy-dialog--confirm" data-allow-immersion @click="acceptTermsAndConditions">
                    {{ $t('legal.acceptance.popup.cta.label') }}
                </VJoyButton>
            </template>
        </VJoyDialog>
    </div>
</template>

<script setup lang="ts">
    import {MaltImg} from '#components';
    import {storeToRefs} from 'pinia';
    import {ref, watch} from 'vue';
    import {VJoyDialog, VJoyButton} from '@maltjoy/core-vue';
    import {useAuth, useContent, useFeatureFlag, useLocale, useWebPreferences} from '#imports';

    const ISO_DATE_RE = /^\d{4}-\d{2}-\d{2}$/;
    const TERMS_AND_CONDITIONS_UPDATE_ACKNOWLEDGEMENT_ID = 'cgu-version-acceptance-acknowledgement';
    const LAST_TERMS_AND_CONDITIONS_VERSION_HUMAND_ID = 'cgu';
    const LAST_TERMS_AND_CONDITIONS_VERSION_TYPE = 'cgu';

    const {locale} = useLocale();
    const {isFeatureEnabledForHost} = useFeatureFlag();
    const {getWebPref, setWebPref} = useWebPreferences();
    const {getLastVersionForContent} = useContent();

    const termsAndConditionsDialog = ref<InstanceType<typeof VJoyDialog>>();
    const auth = useAuth();
    const {loggedIn} = storeToRefs(auth);

    // Should only be used for tests
    const props = defineProps({
        forceLoggedIn: {
            type: Boolean,
            default: false,
        },
    });

    watch(termsAndConditionsDialog, () => {
        if (termsAndConditionsDialog.value) {
            checkIfTermsAndConditionsShouldBeDisplayed();
        }
    });

    async function checkIfTermsAndConditionsShouldBeDisplayed() {
        if (loggedIn.value || props.forceLoggedIn) {
            const featureTermsAndConditionsEnabled = await isFeatureEnabledForHost('freelancer-cgu-modal-on-update');

            if (featureTermsAndConditionsEnabled) {
                const termsAndConditionsVersion = await getTermsAndConditionsVersionAcceptedByCurrentUser();
                const lastTermsAndConditionsVersion = await getLastAvailableTermsAndConditionsVersion();

                const shouldDisplayModal = shouldAskForAcceptanceOfLatestTc(termsAndConditionsVersion || '', lastTermsAndConditionsVersion || '');
                if (shouldDisplayModal) {
                    termsAndConditionsDialog.value?.show();
                }
            }
        }
    }

    function acceptTermsAndConditions() {
        setWebPref(TERMS_AND_CONDITIONS_UPDATE_ACKNOWLEDGEMENT_ID, new Date().toISOString().substring(0, 10));
        termsAndConditionsDialog.value?.hide();
    }

    function getTermsAndConditionsVersionAcceptedByCurrentUser() {
        return getWebPref(TERMS_AND_CONDITIONS_UPDATE_ACKNOWLEDGEMENT_ID);
    }

    function getLastAvailableTermsAndConditionsVersion() {
        return getLastVersionForContent(LAST_TERMS_AND_CONDITIONS_VERSION_HUMAND_ID, locale.value!, LAST_TERMS_AND_CONDITIONS_VERSION_TYPE);
    }

    function shouldAskForAcceptanceOfLatestTc(tcVersion: string, lastTcVersion: string) {
        if (lastTcVersion === '') {
            lastTcVersion = new Date().toISOString().substring(0, 10);
        }

        if (tcVersion === '') {
            return true;
        }

        return isFirstIsoTextDateBeforeSecondIsoTextDate(tcVersion, lastTcVersion);
    }

    function isFirstIsoTextDateBeforeSecondIsoTextDate(dateAsStringCheckedForBeingBefore: string, referenceDateAsString: string) {
        const dateCheckedForBeingBefore = toDateIfValidIsoDate(dateAsStringCheckedForBeingBefore);
        const referenceDate = toDateIfValidIsoDate(referenceDateAsString);
        return dateCheckedForBeingBefore < referenceDate;
    }

    function toDateIfValidIsoDate(dateAsString: string) {
        if (!ISO_DATE_RE.test(dateAsString)) {
            throw new Error(`Invalid ISO date: ${dateAsString}`);
        }

        return new Date(dateAsString);
    }
</script>

<style lang="scss" scoped>
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .tc {
        &__dialog-body {
            display: flex;
            align-items: center;
            gap: var(--joy-core-spacing-8);

            @include mq.screen_xxs {
                flex-direction: column;
            }
        }
    }
</style>
